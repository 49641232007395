import React from 'react';
import parse from 'html-react-parser';

const Section = (props) => {
  const { mode, setActiveSection, sectionName, sectionContent } = props;
  return (
    <section className={`section ${mode === 'kitchenSink' ? "modal": ""}`} >

      <div className="row">

        <h2>{ sectionName }</h2>
        { parse(sectionContent) }
        { mode === 'kitchenSink' &&
          <button className="modal-close" onClick={() => setActiveSection(null)}>Close section</button>
        }
      </div>
    </section>
  )
}

export default Section;
