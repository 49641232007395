import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import animateScrollTo from "animated-scroll-to";

import Section from "./section";

const Home = () => {
  const [mode, setMode] = useState('kitchenSink');
  const [activeSection, setActiveSection] = useState(null);
  const homepageQuery = graphql`
    {
      craft {
        entries(typeId: 2) {
          ... on Craft_homepageModals_homepageModals_Entry {
            title
            id
            modalContent
          }
        }
      }
    }

  `;

  const {craft: {entries}} = useStaticQuery(homepageQuery);

  const homepageModals = entries;


  return (
    <div className={ `home home--${mode}` }>
      <section id="introduction" className="introduction text-center">
        <div className="row">

          <h1>
            <span className="lead plainText-hide">You are about to see the world as seen through the eyes of</span>
            <span className="title" data-title="Kristine Fernandez">Kristine Fernandez</span>
          </h1>
          { mode === 'kitchenSink' &&
            <div >
              <p>
                <strong>Fine Print: </strong>
                If you find yourself annoyed, confused, or overwhelmed by the structure of the page, you can choose to enter <button className="link" onClick={() => setMode('plainText')}>plain text mode</button>.
              </p>
              <p>Otherwise, proceed by scrolling downward.</p>
              <button onClick={() => animateScrollTo((document.querySelector('.main')))} className="arrow icon down-animation">&#x25BE;</button>
            </div>
          }
          { mode === 'plainText' &&
            <div >
              <p>Go back to <button className="link" onClick={() => setMode('kitchenSink')}>kitchen sink mode</button>.</p>
            </div>
          }

        </div>

        <svg className="triangle plainText-hide" enableBackground="new 0 0.099 742 101" height="100" viewBox="0 .099 742 101" width="742" ><path d="m358.491 0 383.509 101.099h-742" fill="#e4e9ec"/></svg>

      </section>
      { mode === 'kitchenSink' &&
        <section className="main">
          <div className="bobblehead edge">
            <svg className="body" version="1.1"

              x="0px" y="0px" width="180px" height="290px" viewBox="0 0 180 290" overflow="visible" enableBackground="new 0 0 180 290"
              >

                <g>
                  <defs>
                  </defs>
                  <polygon fill="#63120E" points="89.877,221.911 68.982,263.911 179.649,264.243 160.982,221.911 "/>
                  <path fill="#190703" d="M103.615,221.314c0,0-1.181,16.056-1.416,17.946c-0.236,1.89-5.432,13.224-4.486,14.168
                    c0.943,0.945,3.541,3.78,7.084,2.599c3.542-1.182,11.098-15.587,11.57-21.018s-3.542-13.695-3.542-13.695l-9.918-1.182"/>
                  <path fill="#190703" d="M148.642,219.897c0,0,1.182,16.057,1.417,17.946c0.236,1.89,5.432,13.224,4.487,14.169
                    c-0.945,0.944-3.542,3.779-7.085,2.598c-3.542-1.182-11.098-15.586-11.569-21.018c-0.474-5.431,3.542-13.695,3.542-13.695
                    l9.916-1.182"/>
                  <path fill="#133B5B" d="M85.315,122.961c0,0,7.261,62.728,10.803,73.708s5.49,26.242,5.49,26.242l12.221,0.699
                    c0,0-9.741-89.961,7.615-74.021s18.419,73.322,18.419,73.322h10.272c0,0,4.959-89.471,0-102.93
                    C145.177,106.521,85.315,122.961,85.315,122.961z"/>
                  <path fill="#E8DED1" d="M103.431,10.272c-5.177-0.117-29.451,2.892-29.805,10.68c-0.354,7.787-5.313,22.664-5.667,24.436
                    c-0.354,1.771-7.792,9.918-7.792,9.918l19.018,4.604c0,0,4.411,58.092,2.615,63.051c-1.797,4.959,74.005,0,74.005,0
                    s-6.376-62.696-2.834-66.593s14.522-7.793,14.522-7.793s-5.634-31.255-15.037-33.692C143.052,12.446,116.942,10.578,103.431,10.272z
                    "/>
                  <path fill="#CE9952" d="M133.158,12.092l-11.654-1.417c-2.065,0-1.83-8.146-1.83-8.146s-12.752-6.73-11.63,1.771
                    c0.562,4.251-2.217,4.428-5.135,4.959l-5.977,1.417c0,0-9.926,16.492,19.474,18.307C133.124,30.014,133.158,12.092,133.158,12.092z"
                    />
                  <path fill="#DBB36B" d="M64.24,28.149c0,0-12.339,67.064-9.978,67.064s25.898-35.151,23.142-57.619
                    c-2.757-22.467-14.582-13.214-14.641-7.084"/>
                  <path fill="#C99E5B" d="M159.17,33.817c0,0-12.34,67.064-9.978,67.064c2.36,0,25.897-35.151,23.14-57.619
                    c-2.756-22.467-14.58-13.214-14.639-7.085"/>
                  <path fill="#CE9952" d="M62.291,84.105L34.982,36.501V17.759c0,0-4.529,3.778-4.529,11.335s-18.24,8.25-19.266,8.501
                    C0.088,40.312-3.406,40.9,3.679,41.846c2.656,0.354,4.43-0.642,8.29-1.417c2.855-0.572,3.839,6.251,6.034,7.438
                    c2.751,1.486,10.779-2.191,10.779-2.191s11.686,55.206,25.381,49.538"/>
                  <path fill="#CEA365" d="M157.256,89.772l-27.273-47.604V23.427c0,0-4.564,3.777-4.564,11.335s-18.257,8.249-19.284,8.501
                    c-11.1,2.716-14.602,3.306-7.518,4.25c2.657,0.354,4.425-0.642,8.286-1.416c2.855-0.573,3.837,6.251,6.031,7.438
                    c2.751,1.486,10.778-2.191,10.778-2.191s11.703,55.206,25.398,49.539"/>
                  <rect x="68.982" y="263.911" fill="#440605" width="111" height="26"/>
                  <rect x="98.317" y="274.078" fill="none" width="62.833" height="25.166"/>
                  <text transform="matrix(1 0 0 1 98.3174 281.2383)" fill="#D8CDBA" fontFamily="'Arial-BoldMT'" fontSize="10">KRISTINE</text>
                </g>
            </svg>

            <svg className="head" version="1.1"

              x="0px" y="0px" width="149px" height="220px" viewBox="0 0 149 220" overflow="visible" enableBackground="new 0 0 149 220"
              >

                <g>
                  <defs>
                  </defs>
                  <path fill="#000000" d="M43.985,187.706c0,0,15.375-33.952,37.487-33.952c6.265,0,55.908,20.596,55.908,20.596
                    s-2.318-98.445-21.849-108.694C96,55.406,15.709,63.958,11.426,86.417C7.142,108.876,43.985,187.706,43.985,187.706z"/>
                  <path fill="#DBB36B" d="M12.018,72.687c-5.196,19.292,19.319,67.91,46.771,79.629c27.451,11.721,56.026,5.171,76.256-36.808
                    c9.66-20.044-4.068-74.375-31.52-80.686C76.073,28.513,28.285,12.285,12.018,72.687z"/>
                  <path fill="#C99E59" d="M49.781,83.743c7.557,0,11.334,20.241,6.612,24.29c-4.723,4.047-27.678,4.936-27.678,4.936
                    s-0.286-31.114,0-29.225"/>
                  <path fill="#C99E59" d="M95.373,82.326c-7.557,0-11.334,20.242-6.611,24.29s27.678,4.935,27.678,4.935s0.286-31.113,0-29.224"/>
                  <path fill="#604113" d="M117.672,95.549l-5.668-2.903c0,0-26.558-4.674-29.572,0c-3.016,4.674-3.016,4.674-3.016,4.674
                    l-12.751-1.417l-2.913-5.213c0,0-36.641-7.086-36.641,0.54s3.424,1.417,3.424,1.417s-1.417,23.802,6.73,25.572
                    c8.147,1.771,25.419-1.771,26.878-2.125s3.23-17.711,3.23-17.711l12.915,0.708c0,0-1.226,17.357,5.15,17.357
                    c6.375,0,24.086,2.834,27.628-5.313c3.543-8.147,0-14.523,0-14.523L117.672,95.549z M62.06,112.968
                    c-0.354,2.895-15.595,3.126-15.595,3.126s-9.2,2.125-11.62-1.417s-2.539-21.984-2.539-21.984c4.959-4.273,24.795,0.731,28.337,0.063
                    s3.188,4.141,3.188,4.141C65.627,103.419,62.414,110.072,62.06,112.968z M107.399,112.968c-4.604,4.312-14.169,2.063-21.548,0
                    c-7.379-2.064-1.477-20.211-1.477-20.211c9.21-1.022,27.394,3.04,27.394,3.04C115.065,109.448,112.004,108.655,107.399,112.968z"/>
                  <path fill="#231909" d="M66.799,41.828c0,0-16.119,35.238-25.544,43.806s-17.057,66.141-6.775,69.083
                    c10.282,2.945,23.175,9.825,22.318,23.107c-0.856,13.282-8.422,28.718-8.422,28.718l-7.828,6.004c0,0-11.484-15.419-10.881-16.275
                    c0.604-0.857-7.696,10.284-7.696,10.284L7.997,167.998c0,0-17.993-100.25,0-125.956c17.994-25.705,51.507-63.49,104.283-26.776
                    c36.025,25.062,36.24,134.739,36.24,134.739s0.857,59.121-6.854,62.55c-7.712,3.427-17.138,7.711-17.138,7.711
                    s0.187-17.993-1.528-20.564c-1.715-2.57-12.67,5.141-12.67,5.141v-14.138c0,0,6.992-84.808-2.434-97.455
                    C98.471,80.601,106.75,39.043,66.799,41.828z"/>
                  <path fill="#282117" d="M91.191,81.175l-0.01,3.792c0,0,8.938-3.416,16.281-2.067C114.806,84.248,97.542,75.507,91.191,81.175z"/>
                  <path fill="#282117" d="M53.445,80.778l0.496,4.002c0,0-9.875-2.461-17.455-0.098C28.908,87.045,46.015,75.607,53.445,80.778z"/>
                  <path fill="#493838" d="M60.998,130.376c-2.028-0.338,26.807,1.789,27.998-1.666c1.191-3.457,0.709,16.046-14.539,15.726
                    c-6.902-0.146-5.834-1.787-7.859-7.506C64.783,131.803,62.428,130.616,60.998,130.376z"/>
                  <path fill="#F9EFEF" d="M63.315,130.418l1.248,1.22c0,0,14.13,1.769,20.919-0.61C92.271,128.649,77.248,131.649,63.315,130.418z"/>
                </g>
            </svg>

          </div>
          <svg className="headphones edge" version="1.1"

            x="0px" y="0px" width="1444px" height="394px" viewBox="52.118 0 1444 394"
            overflow="visible" enableBackground="new 52.118 0 1444 394" >

                <g>
                  <defs>
                  </defs>
                  <path fill="#47413F" d="M1307.765,273.388c0,0-13.302-52.739-55.271-44.056s-134.593,14.472-146.17,85.386
                    c-11.578,70.914,66.571,70.914,72.361,70.914C1184.474,385.632,1317.854,358.775,1307.765,273.388z"/>
                  <path fill="#7C7A56" d="M1315.64,284.249c0,0-11.783-46.72-48.963-39.027s-119.231,12.82-129.487,75.641s58.974,62.82,64.103,62.82
                    S1324.577,359.892,1315.64,284.249z"/>
                  <path fill="#565046" d="M979.983,128.862v-34c0,0,214.896-189.384,412.333-32.974s-67.946,235.896-92.306,256.41
                    s-91.026,24.359-91.026,24.359l-11.538-25.628l62.82-37.167h35.717l5.309,12.795c0,0,224.008-84.614,108.975-183.333
                    c0,0,0,11.539-8.975,7.692S1293.6,33.683,1293.6,33.683l6.411-8.974c0,0-110.258-26.923-198.719,19.231
                    s-74.358,33.333-74.358,33.333l20.656,11.583C1047.59,88.855,983.033,118.914,979.983,128.862z"/>
                  <path fill="none" stroke="#565046" strokeWidth="6" strokeMiterlimit="10" d="M1044.881,172.145
                    c-25.641,43.59-81.448,17.1-233.333,7.692c-137.368-8.508-124.231-40.507-177.458-76.229c-57.39-38.517-162.947-74.481-227.989,0
                    c-72,82.449-77.792,71.982-202.49,55.097C97.223,144.298,2.621,314.024,2.621,314.024"/>
                  <path fill="#7C7A56" d="M1121.001,126.861c0,0-9.94-58.563-88.146-34.204s-79.636,53.182-84.45,65.327
                    c-9.351,23.588,16.502,51.34,28.04,46.211"/>
                  <path fill="#47413F" d="M974.599,165.478c-34.615,76.924,114.103,47.437,142.308-19.23S1022.035,94.965,974.599,165.478"/>
                  <path fill="#DB8F09" d="M1010.157,162.978c-18.344,40.766,60.47,25.139,75.417-10.191
                    C1100.521,117.457,1035.297,125.609,1010.157,162.978"/>
                  <path fill="none" stroke="#565046" strokeWidth="6" strokeMiterlimit="10" d="M1221.432,327.862c0,0-75.492,57.695-194.498,30.179
                    c-44.354-10.256-80.88-32.051-132.052-101.281c-20.216-27.351-31.398-69.541-120.797-80.226"/>
                </g>

            </svg>

              <svg className="arm edge tip-top" data-tooltip title="I have shaky hands; reasons unknown" version="1.1"

                x="0px" y="0px" width="153px" height="525px" viewBox="0.438 1.256 153 525"
                overflow="visible" enableBackground="new 0.438 1.256 153 525" >

                  <g>
                    <defs>
                    </defs>
                    <path fill="#CCA269" d="M48.449,45.694c0,0-5.697,0.54-8.862,2.815c-5.981,4.302-11.04,10.747,9.702,10.747c8.75,0,8.75,0,8.75,0
                      S56.517,53.887,48.449,45.694"/>
                    <path fill="#D8B86F" d="M30.623,526.256c0,0,39.589-254.068,33.539-274.766c-6.05-20.7-42.227-87.478-42.227-87.478
                      s8.129-75.99,0.062-79.734c-8.067-3.743-16.103-38.476,14.15-21.11c30.252,17.367,19.176,64.326,19.176,64.326
                      s14.125-41.355,5.049-64.326C51.297,40.2,14.654,22.164,14.654,22.164S-2.548,18.008,1.688,7.422
                      C5.924-3.162,40.717,8.624,40.717,8.624S36.246-2.098,77.591,0.368c41.267,2.46,27.161-1.513,41.278,29.748
                      C132.987,61.377,153.66,134.02,153.66,134.02l-23.699,106.065l-4.033,286.171"/>
                    <path fill="#E2C283" d="M15.004,69.109c0,0,10.753,6.385,10.418,10.084c-0.335,3.695-4.368,4.026-4.368,4.026
                      S10.97,71.126,15.004,69.109z"/>
                    <path fill="#E2C283" d="M10.97,9.276c0,0-25.555-4.474,1.341-6.259c2.7-0.18,5.172,0.28,5.172,0.28S18.366,8.268,10.97,9.276z"/>
                  </g>

              </svg>
              <svg data-tooltip className="edge macbook" title="I &hearts; my Macbook" version="1.1"

                x="0px" y="0px" width="359px" height="689px" viewBox="496 0 359 689" overflow="visible" enableBackground="new 496 0 359 689"
                >

                  <g>
                    <defs>
                    </defs>
                    <path fill="#726868" d="M833.013,477.78c0.123,0.211,0.052,0.482-0.16,0.604l-12.202,7.095c-0.212,0.122-0.482,0.051-0.605-0.16
                      l-0.058-0.1c-0.123-0.211-0.052-0.482,0.159-0.604l12.203-7.095c0.212-0.122,0.482-0.051,0.605,0.16L833.013,477.78z"/>
                    <path fill="#EAEAEA" d="M847.564,436.436L605.75,281.308L496,337.82v320.02c66-36.839,332.471-187.637,351.314-199.609
                      C863.416,448,847.564,436.436,847.564,436.436z"/>
                    <path fill="#DDD7D7" d="M761,403.252L618,302h-6.162L496,363.374v189.25c104-56.079,260.096-140.315,264.75-143.671
                      C767.75,403.907,761,403.252,761,403.252z"/>
                    <path fill="#E5E5E5" stroke="#D1D1D1" strokeMiterlimit="10" d="M535.668,540.361l92-48.469c0,0,5.549,0.281,8.441,2.861
                      c2.892,2.58,54.372,36.592,54.372,36.592s3.187,6.043-2.813,9.016s-84.592,47.306-84.592,47.306s-6.244,5.658-11.662,4
                      c-5.346-1.637-61.787-46.351-61.787-46.351S531.562,542.523,535.668,540.361z"/>
                    <path fill="#726868" d="M703.244,428.24c2.057,1.461,2.725,3.182,1.494,3.846l-14.855,7.988c-1.233,0.661-3.898,0.016-5.955-1.447
                      l-6.209-4.408c-2.055-1.459-2.727-3.182-1.494-3.842l14.859-7.987c1.227-0.663,3.895-0.017,5.952,1.444L703.244,428.24z"/>
                    <path fill="#726868" d="M749.436,403.404c2.057,1.462,2.727,3.182,1.494,3.843l-14.857,7.989c-1.232,0.664-3.898,0.016-5.954-1.445
                      l-6.206-4.408c-2.058-1.459-2.728-3.181-1.497-3.842l14.86-7.99c1.229-0.66,3.894-0.015,5.951,1.445L749.436,403.404z"/>
                    <path fill="#726868" d="M551.92,379.475c2.059,1.461,2.599,3.254,1.206,4.003l-15.957,8.578c-1.392,0.747-4.188,0.173-6.243-1.29
                      l-23.592-16.75c-2.057-1.462-2.598-3.251-1.205-4l15.956-8.58c1.394-0.748,4.189-0.168,6.245,1.29L551.92,379.475z"/>
                    <path fill="#726868" d="M575.438,366.832c2.059,1.462,2.595,3.251,1.205,4l-15.957,8.579c-1.394,0.749-4.189,0.171-6.245-1.29
                      l-23.591-16.75c-2.057-1.461-2.598-3.251-1.205-3.998l15.957-8.58c1.393-0.749,4.188-0.171,6.246,1.289L575.438,366.832z"/>
                    <path fill="#726868" d="M598.952,354.188c2.059,1.461,2.597,3.25,1.204,3.998l-15.957,8.58c-1.392,0.751-4.186,0.171-6.244-1.288
                      l-23.59-16.75c-2.055-1.461-2.596-3.252-1.204-4l15.957-8.582c1.39-0.748,4.188-0.168,6.243,1.292L598.952,354.188z"/>
                    <path fill="#726868" d="M569.049,413.645c2.055,1.459,2.596,3.25,1.204,3.999l-15.956,8.581c-1.394,0.746-4.191,0.17-6.246-1.292
                      l-23.588-16.749c-2.059-1.461-2.598-3.252-1.207-4l15.957-8.58c1.393-0.749,4.188-0.172,6.244,1.289L569.049,413.645z"/>
                    <path fill="#726868" d="M592.564,401.001c2.057,1.458,2.594,3.25,1.203,3.999l-15.955,8.579c-1.393,0.748-4.188,0.171-6.244-1.291
                      l-23.592-16.75c-2.057-1.458-2.597-3.252-1.205-3.999l15.959-8.58c1.389-0.748,4.184-0.169,6.241,1.289L592.564,401.001z"/>
                    <path fill="#726868" d="M616.079,388.355c2.058,1.462,2.595,3.252,1.205,4l-15.957,8.58c-1.394,0.748-4.187,0.171-6.245-1.291
                      l-23.588-16.75c-2.059-1.463-2.596-3.251-1.205-4l15.957-8.578c1.393-0.748,4.187-0.172,6.244,1.289L616.079,388.355z"/>
                    <path fill="#726868" d="M611.369,434.264c2.059,1.459,2.596,3.251,1.206,3.999l-15.957,8.581c-1.392,0.746-4.188,0.17-6.243-1.289
                      l-23.59-16.75c-2.059-1.461-2.598-3.254-1.207-4.002l15.958-8.58c1.39-0.748,4.187-0.169,6.243,1.291L611.369,434.264z"/>
                    <path fill="#726868" d="M634.886,421.62c2.058,1.461,2.595,3.249,1.205,3.999l-15.956,8.578c-1.394,0.75-4.188,0.172-6.245-1.288
                      l-23.589-16.749c-2.057-1.461-2.598-3.252-1.207-4.001l15.957-8.58c1.391-0.749,4.188-0.171,6.246,1.29L634.886,421.62z"/>
                    <path fill="#726868" d="M658.402,408.977c2.055,1.461,2.598,3.252,1.205,3.999l-15.958,8.58c-1.392,0.747-4.188,0.17-6.245-1.29
                      l-23.59-16.752c-2.055-1.459-2.594-3.25-1.206-3.998l15.96-8.58c1.391-0.748,4.188-0.17,6.245,1.293L658.402,408.977z"/>
                    <path fill="#726868" d="M681.406,439.983c2.06,1.46,2.598,3.251,1.207,4l-15.957,8.579c-1.394,0.745-4.189,0.169-6.246-1.291
                      l-23.59-16.751c-2.056-1.46-2.595-3.251-1.201-3.997l15.955-8.581c1.392-0.749,4.188-0.171,6.244,1.29L681.406,439.983z"/>
                    <path fill="#726868" d="M639.596,375.712c2.057,1.461,2.594,3.25,1.204,3.999l-15.958,8.58c-1.389,0.75-4.186,0.17-6.243-1.289
                      l-23.591-16.752c-2.055-1.459-2.595-3.25-1.205-3.996l15.959-8.582c1.389-0.749,4.188-0.17,6.244,1.291L639.596,375.712z"/>
                    <path fill="#726868" d="M622.469,341.545c2.057,1.458,2.598,3.25,1.203,3.998l-15.956,8.58c-1.392,0.748-4.185,0.172-6.243-1.289
                      l-23.591-16.75c-2.057-1.461-2.595-3.253-1.206-4.001l15.958-8.579c1.392-0.748,4.187-0.17,6.247,1.29L622.469,341.545z"/>
                    <path fill="#726868" d="M645.984,328.9c2.057,1.461,2.598,3.251,1.204,3.999l-15.958,8.581c-1.391,0.748-4.186,0.17-6.243-1.29
                      l-23.591-16.751c-2.058-1.459-2.594-3.25-1.203-3.999l15.955-8.581c1.394-0.747,4.189-0.169,6.244,1.291L645.984,328.9z"/>
                    <path fill="#726868" d="M545.531,426.288c2.057,1.46,2.598,3.25,1.207,3.997l-15.96,8.582c-1.392,0.748-4.187,0.171-6.243-1.291
                      l-23.592-16.75c-2.056-1.459-2.596-3.251-1.203-3.999l15.957-8.579c1.391-0.748,4.188-0.171,6.244,1.29L545.531,426.288z"/>
                    <path fill="#726868" d="M564.34,459.552c2.057,1.463,2.596,3.252,1.202,3.999l-15.955,8.58c-1.393,0.747-4.188,0.17-6.244-1.289
                      l-23.591-16.752c-2.057-1.461-2.595-3.249-1.207-3.998l15.957-8.581c1.393-0.749,4.189-0.169,6.246,1.293L564.34,459.552z"/>
                    <path fill="#726868" d="M587.855,446.908c2.055,1.461,2.596,3.251,1.203,3.999l-15.955,8.58c-1.395,0.747-4.189,0.17-6.246-1.293
                      l-23.59-16.749c-2.055-1.459-2.596-3.25-1.206-3.999l15.957-8.577c1.394-0.75,4.188-0.172,6.247,1.288L587.855,446.908z"/>
                    <path fill="#726868" d="M680.749,353.584c2.056,1.461,2.57,3.266,1.147,4.03l-33.481,18.002c-1.423,0.767-4.244,0.202-6.302-1.259
                      l-23.59-16.751c-2.056-1.46-2.57-3.263-1.147-4.029l33.483-18.002c1.42-0.766,4.241-0.201,6.297,1.26L680.749,353.584z"/>
                    <path fill="#726868" d="M715.511,378.27c2.058,1.461,2.096,3.521,0.088,4.602l-47.315,25.438c-2.008,1.08-5.306,0.773-7.362-0.688
                      l-23.589-16.75c-2.059-1.459-2.094-3.521-0.085-4.601l47.31-25.438c2.011-1.082,5.309-0.772,7.365,0.688L715.511,378.27z"/>
                    <path fill="#726868" d="M634.303,435.876c-2.058-1.46-4.854-2.036-6.243-1.29l-19.319,10.385c-1.391,0.75-0.852,2.543,1.205,4
                      l23.592,16.753c2.053,1.459,4.852,2.036,6.242,1.287l19.318-10.384c1.389-0.75,0.852-2.541-1.208-4L634.303,435.876z"/>
                    <path fill="#726868" d="M705.252,399.277c-2.059-1.461-4.854-2.037-6.246-1.29l-16.794,9.031c-1.393,0.749-0.854,2.54,1.202,4
                      l22.35,15.869c2.056,1.46,4.85,2.037,6.244,1.288l16.795-9.031c1.394-0.748,0.852-2.537-1.203-3.997L705.252,399.277z"/>
                    <path fill="#0F0D0D" d="M599.42,269c-1.787-3.613-92.654-259-92.654-259S505,6.356,496,6.958v331.706l103.17-53.96
                      C599.17,284.704,604.32,278.903,599.42,269z"/>
                    <polygon fill="#E1F2F4" points="496,49.127 496,306.582 576.159,265.335 "/>
                    <polygon fill="#383636" points="496,347.088 552.139,317 540.915,310.533 496,334.352 "/>
                    <path fill="#BFBFBF" d="M496,655.779v25.047c58-33.303,344.555-197.83,348.748-200.825c2.372-1.694,9.848-5.726,9.848-9.834
                      c0-5,0.092-22.167,0.092-22.167L496,655.779z"/>
                    <rect x="0.5" y="0.5" display="none" fill="#FFFFFF" stroke="#000000" strokeMiterlimit="10" width="496" height="715"/>
                    <path fill="#726868" d="M503.8,405.216l-7.8-5.36v14.065l8.877-4.706C506.271,408.467,505.855,406.676,503.8,405.216z"/>
                    <path fill="#726868" d="M527.566,392.572l-23.592-16.75c-2.059-1.461-5.104-2.039-6.493-1.29L496,375.194v21.518l10.318,7.149
                      c2.056,1.461,4.979,2.037,6.37,1.29l16.021-8.579C530.098,395.824,529.623,394.031,527.566,392.572z"/>
                    <path fill="#726868" d="M522.014,438.932l-23.84-16.75c-0.597-0.424-1.174-0.763-2.174-1.027v25.857l4.77,3.209
                      c2.057,1.462,4.977,2.035,6.369,1.289l16.02-8.578C524.551,442.184,524.072,440.393,522.014,438.932z"/>
                    <path fill="#726868" d="M517.307,484.841L496,469.711v26.196l0.311,0.221c2.059,1.463,4.853,2.04,6.242,1.291l15.959-8.581
                      C519.905,488.091,519.363,486.3,517.307,484.841z"/>
                    <path fill="#726868" d="M498.251,451.575L496,450.154v7.142l3.329-1.724C500.723,454.826,500.309,453.037,498.251,451.575z"/>
                    <path fill="#726868" d="M631.016,467.076l-23.59-16.75c-2.055-1.459-5.116-2.028-6.529-1.271L496,505.32v20.284l11.309,7.852
                      c2.057,1.462,4.993,2.032,6.404,1.272l118.412-63.634C633.535,470.336,633.074,468.538,631.016,467.076z"/>
                    <path fill="#726868" d="M540.822,472.197l-23.59-16.754c-2.058-1.456-5.1-2.035-6.496-1.287L496,461.946v4.977l23.576,16.563
                      c2.058,1.459,4.979,2.035,6.368,1.288l16.021-8.58C543.357,475.445,542.878,473.655,540.822,472.197z"/>
                  </g>
          </svg>
          <div className="row">

          <button id="contact" className="item" onClick={() => setActiveSection('contact')} >

            <svg version="1.1"

              x="0px" y="0px" width="355px" height="313px" viewBox="0.919 -0.033 355 313"
              overflow="visible" enableBackground="new 0.919 -0.033 355 313" >
              <g>
                <defs>
                </defs>
                <path fill="#BCC7C6" d="M5.668,145.336C13.4,89.369,75.727,28.162,159.431,25.828c83.706-2.342,194.768,37.706,196.204,136.046
                  c0.767,52.562-23.208,110.747-84.527,129.302c-33.466,10.13-146.342,21.617-211.987-19.183
                  C4.485,238.033,3.133,163.689,5.668,145.336z"/>
                <path fill="#7B858E" d="M0.699,140.268C8.253,82.833,69.147,20.018,150.925,17.624c81.779-2.403,190.288,38.695,191.69,139.617
                  c0.749,53.938-22.674,113.65-82.583,132.692c-32.695,10.396-142.975,22.186-207.11-19.686
                  C-0.455,235.397-1.776,159.104,0.699,140.268z"/>
                <path fill="#F4ECE1" d="M4.831,141.218C12.227,84.975,71.856,23.466,151.937,21.121c80.081-2.354,186.337,37.891,187.71,136.718
                  c0.734,52.818-22.202,111.291-80.869,129.938c-32.016,10.179-140.004,21.724-202.809-19.277
                  C3.7,234.373,2.405,159.664,4.831,141.218z"/>
                <path className="bread" fill="#DD9C4E" d="M48.436,112.584c0,0-13.083,2.505-19.574-26.216c-6.492-28.721,15.816-36.862,18.835-37.44
                  c3.018-0.578,222.439-37.785,242.688-27.165c20.248,10.62,28.962,39.882,18.143,47.756c-10.819,7.872-16.768,9.418-16.768,9.418
                  s29.812,150.474,26.199,176.296c-3.612,25.826-34.303,20.105-43.737,24.813c-9.434,4.707-182.65,28.023-182.65,28.023
                  s-15.261,2.159-17.895-23.435C71.042,259.043,48.436,112.584,48.436,112.584z"/>
                <path className="bread" fill="#FFF7E3" d="M38.71,106.713c0,0-13.083,2.507-19.575-26.215c-6.492-28.72,15.817-36.861,18.835-37.439
                  C40.99,42.48,260.41,5.276,280.659,15.894c20.248,10.621,28.965,39.882,18.143,47.755c-10.817,7.871-16.77,9.419-16.77,9.419
                  s29.813,150.473,26.201,176.297c-3.609,25.821-34.303,20.104-43.737,24.811c-9.437,4.708-182.649,28.022-182.649,28.022
                  s-15.261,2.161-17.896-23.431C61.315,253.173,38.71,106.713,38.71,106.713z"/>
                <path className="lettuce" fill="#C8DD9B" d="M62.123,38.431c-5.899,1.574-48.061,23.313-29.41,66.818c18.649,43.504,34.907,82.92,37.252,97.935
                  c2.344,15.02-5.382,55.163,7.655,71.033c13.035,15.865,31.266,50.072,97.398,3.569c66.133-46.501,93.759-21.828,97.366-24.453
                  c3.604-2.626,56.12-86.149,2.096-181.158c-54.02-95.01-47.153-69.262-57.424-68.26c-10.275,1.002-93.886,30.553-106.968,33.061
                  C97.007,39.481,62.123,38.431,62.123,38.431z"/>
                <path className="cheese" fill="#FFD664" d="M37.923,61.433c0,0,26.488,224.012,29.209,236.057c2.724,12.043,233.719-38.979,237.834-53.302
                  c4.11-14.317-31.812-233.623-36.257-234.703C264.266,8.403,24.766,31.089,37.923,61.433z"/>
                <path className="ham bottom" fill="#D36A6A" d="M70.505,52.292c-4.917,1.38-50.147,172.963,4.347,228.253c54.493,55.289,244.592-44.695,227.716-71.544
                  c-16.876-26.847,5.128-166.389-68.659-190.916C160.12-6.443,103.085,43.15,70.505,52.292z"/>
                <path className="ham top" fill="#EF9E9E" d="M69.666,48.585c-4.915,1.381-50.145,172.964,4.349,228.253c54.492,55.289,244.591-44.694,227.718-71.541
                  c-16.876-26.852,5.125-166.39-68.66-190.917C159.283-10.149,102.248,39.443,69.666,48.585z"/>
                <path className="letter" fill="#FFFB5F" d="M101.411,142.551c-5.198,17.641-5.287,37.243-11.634,45.52c-6.347,8.275-7.935,18.256,0,19.354
                  c7.933,1.098,15.865-15.695,16.394-25.802c0.529-10.106,2.116-22.279,2.116-22.279s7.933,22.4,11.106,19.478
                  c3.172-2.924,7.386-16.268,6.016-22.334c-1.371-6.067,4.763,16.499,8.646,24.22c3.885,7.717,11.172,16.929,14.755,17.589
                  c4.957,0.911,17.188,5.357,2.38-10.59c-14.809-15.95-5.554-5.657-15.072-19.173c-2.654-3.767-7.206-22.812-7.735-25.981
                  c-0.528-3.171-7.404-6.579-9.519,2.428c-2.116,9.009-2.116,13.392-2.116,13.392S105.642,128.19,101.411,142.551z"/>
                <path className="letter" fill="#FFFB5F" d="M173.395,167.505c-3.428-1.894-5.482-0.316-8.566,0.945s-2.831,3.152-6.555,1.893
                  c-3.725-1.262-3.038-3.469-3.381-5.045c0,0,23.642-2.842,11.648-15.143c-11.992-12.303-29.467,2.838-23.299,13.563
                  c6.168,10.728,9.594,15.456,19.529,14.194C172.709,176.651,176.822,169.397,173.395,167.505z M163.116,154.887
                  c0,0-1.029,3.17-8.566,2.531C154.549,157.418,153.523,151.102,163.116,154.887z"/>
                <path fill="#FFFFFF" d="M228.948,201.803c-2.981-0.225-3.907,1.523-5.648,3.381c-1.738,1.856-0.962,3.09-3.95,3.396
                  c-2.986,0.31-3.211-1.441-3.951-2.428c0,0,15.515-9.489,3.276-14.221c-12.241-4.729-14.548,13.205-6.854,18.693
                  s6.562,5.817,13.061,1.782S231.931,202.028,228.948,201.803z M217.799,196.308c0,0,0.292,2.528-5.146,4.482
                  C212.654,200.79,209.934,196.729,217.799,196.308z"/>
                <path className="letter" fill="#FFFB5F" d="M209.446,155.202v-5.047l-3.989-11.479c0,0-9.94-4.198-8.335,4.586c0,0-12.062-10.7-16.862,3.734
                  c-4.797,14.437,2.194,21.574,5.617,21.574c3.429,0,14.354-7.947,14.354-7.947s3.485,9.772,9.994,1.245
                  C216.739,153.338,209.446,155.202,209.446,155.202z M192.028,159.302c0,0-9.748,7.482-6.358-4.415
                  c3.393-11.898,10.965-4.101,10.965-4.101C196.634,155.203,192.028,159.302,192.028,159.302z"/>
                <path  className="letter" fill="#FFFB5F" d="M211.999,124.289c0,0-16.049,3.285-14.337,6.532c1.714,3.246,16.789,0,16.789,0s5.568,39.839,10.323,38.891
                  c1.33-0.264,4.938,1.62,4.754-2.838c-0.477-11.482-7.766-36.667-7.814-38.485c0,0,17.064-1.887,12.268-6.305
                  c-4.797-4.419-11.513-0.944-12.268,0c-0.752,0.942-1.74-14.828-5.529-13.567c-3.789,1.262-3.213,0.945-4.186,4.731
                  C211.025,117.034,211.999,124.289,211.999,124.289z"/>
                <path fill="#FFFFFF" d="M137.446,114.422v-3.582l-1.986-8.146c0,0-6.633-2.979-5.497,3.256c0,0-8.349-7.595-11.755,2.649
                  c-3.403,10.244,1.661,15.31,4.094,15.31c2.431,0,10.238-5.64,10.238-5.64s1.656,6.936,6.276,0.883
                  C143.437,113.1,137.446,114.422,137.446,114.422z M126.769,117.331c0,0-6.917,5.31-4.51-3.133c2.405-8.443,7.78-2.91,7.78-2.91
                  C130.04,114.423,126.769,117.331,126.769,117.331z"/>
                <path fill="#FFFFFF" d="M160.389,86.967c0,0-9.818,4.528-8.228,6.357c1.59,1.828,10.788-2.53,10.788-2.53
                  s9.579,24.763,12.493,23.438c0.814-0.37,3.417,0.298,2.628-2.54c-2.037-7.308-10.514-22.394-10.819-23.556
                  c0,0,10.682-3.782,6.934-5.898c-3.748-2.117-7.542,1.127-7.883,1.848c-0.342,0.72-3.354-9.266-5.599-7.885
                  c-2.243,1.381-1.922,1.09-1.977,3.67C158.671,82.45,160.389,86.967,160.389,86.967z"/>
                <path fill="#FFFFFF" d="M190.955,76.644c0,0-5.246,3.593-3.834,5.562c1.411,1.968,6.024-1.512,6.024-1.512s7.229,25.547,10.252,24.5
                  c0.846-0.293,3.375,0.616,2.853-2.284c-1.345-7.466-8.378-23.276-8.573-24.461c0,0,10.988-2.769,7.454-5.228
                  c-3.535-2.456-7.613,0.42-8.02,1.105c-0.409,0.684-2.477-9.538-4.841-8.373c-2.362,1.166-2.017,0.906-2.309,3.47
                  C189.664,71.985,190.955,76.644,190.955,76.644z"/>
                <path fill="#FFFFFF" d="M86.415,112.288c-1.241,3.572,6.508,22.075,10.457,18.125c3.95-3.951,4.183-8.785,4.183-8.785
                  s4.88,15.295,9.063,2.628c4.183-12.665,2.584-14.927,1.175-17.398c-1.408-2.471-2.801,1.57-3.267,2.688
                  c-0.466,1.116-1.162,13.2-1.162,13.2s-7.668-11.388-8.598-9.528c-0.931,1.859-3.281,12.315-3.281,12.315
                  S88.274,106.942,86.415,112.288z"/>
                <path fill="#FFFFFF" d="M220.427,77.045c-5.048-6.197-25.895,19.504-5.382,17.889C235.557,93.317,221.702,78.608,220.427,77.045z
                  M214.9,90.934c-10.367,0.815,0.17-12.174,2.721-9.042C218.266,82.682,225.268,90.117,214.9,90.934z"/>
                <path fill="#FFFFFF" d="M144.08,101.406c0,0,2.785,13.438,1.547,16.737c-1.237,3.301,5.468,1.938,5.468,1.938l-1.444-12.791
                  c0,0,7.682-3.499,8.253,4.516c0.37,5.181-0.103-0.489,1.651,7.864c0,0,5.581-6.728,3.814-8.668
                  c-1.768-1.94-5.93-14.026-9.411-12.376c-3.482,1.649-5.132,3.355-5.132,3.355S145.935,96.346,144.08,101.406z"/>
                <path fill="#FFFFFF" d="M194.214,200.713c8.36-5.488,14.443,17.163,14.542,20.053c0.1,2.887-5.877,2.062-5.464,1.648
                  c0.413-0.41-4.127-9.076-6.189-13.203s-4.951,2.89-4.951,2.89s2.062,7.427,2.889,11.552c0.822,4.13-4.54,2.064-4.932,2.476
                  c-0.396,0.416-3.733-8.665-4.146-11.552c-0.414-2.887-6.512-2.476-6.512-2.476s2.798,18.568,0,18.979
                  c-2.797,0.415-5.867-15.68-6.576-23.107c-0.708-7.427,4.423-2.744,4.423-2.744c3.996-6.267,10.916,3.569,10.916,3.569
                  S190.502,203.149,194.214,200.713z"/>
                <path fill="#FFFFFF" d="M229.702,190.643c1.845,1.008,4.69-8.252,4.69-8.252s0.261-9.381,6.45-1.804
                  c6.189,7.58-2.064,8.847-2.064,10.07s0.89,12.229,4.366,11.678c3.473-0.552,2.65-3.852,2.65-6.327s6.187-3.303,4.535-8.665
                  c-1.648-5.365-5.466-22.688-15.938-16.296c-10.469,6.393-6.987,11.344-6.987,11.344S225.162,188.167,229.702,190.643z"/>
                <path fill="#FFFFFF" d="M243.73,211.273c2.137,0.986,7.219,3.506,5.778-3.095C248.062,201.578,239.944,209.527,243.73,211.273z"/>
                </g>
            </svg>
          </button>

          <button id="about" className="item" onClick={() => setActiveSection('about')}>


            <svg className="smoke" version="1.1" id="Layer_1"  x="0px" y="0px"
              width="27.333px" height="158.333px" viewBox="0 0 27.333 158.333" enableBackground="new 0 0 27.333 158.333"
              >

                <g>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" d="M3.538,148c0,0,33.824-22.865,12.536-46.026
                    c-21.288-23.159-8.471-48.498-2.75-56.421C23.107,32,17.446,15.399,3.538-0.062"/>
                </g>
            </svg>
            <svg className="smoke" version="1.1" id="Layer_1"  x="0px" y="0px"
              width="27.333px" height="158.333px" viewBox="0 0 27.333 158.333" enableBackground="new 0 0 27.333 158.333"
              >

                <g>
                  <path fill="none" stroke="#FFFFFF" strokeWidth="2" strokeMiterlimit="10" d="M3.538,148c0,0,33.824-22.865,12.536-46.026
                    c-21.288-23.159-8.471-48.498-2.75-56.421C23.107,32,17.446,15.399,3.538-0.062"/>
                </g>

            </svg>
            <svg className="smoke" version="1.1" id="Layer_1"  x="0px" y="0px"
              width="27.333px" height="158.333px" viewBox="0 0 27.333 158.333" enableBackground="new 0 0 27.333 158.333"
              >

            </svg>

            <svg className="mug" version="1.1"

              x="0px" y="0px" width="208px" height="246px" viewBox="-2.117 -0.205 208 246"
              overflow="visible" enableBackground="new -2.117 -0.205 208 246" >

                <g>
                  <defs>
                  </defs>
                  <path fill="#BCC7C6" d="M23.729,169.521c11.597-20.205,35.128-34.245,74.875-35.056c39.751-0.816,96.601,10.173,97.062,44.427
                    c0.461,34.254-2.772,51.062-31.891,57.524C134.659,242.877,35.006,243,27.149,211.009C27.149,211.009,14.351,185.856,23.729,169.521
                    z"/>
                  <path fill="#FFE8DE" d="M3.99,113.663c6.402,41.9-10.11,85.068,38.013,124.1c15.138,12.278,87.18,9.542,105.451-4.984
                    c31.773-25.259,18.702-79.233,21.746-110.577c2.833-29.189,2.833-29.189-1.549-71.038c-3.398-32.447-96.325-35.897-123.795-20.281
                    C17.135,46.074-6.521,44.878,3.99,113.663z"/>
                  <path fill="#FFE8DE" d="M165.875,88.288c-0.194-0.505,5.497,5.198,6.584-1.521c1.087-6.719-4.748-23.707,5.935-20.526
                    s15.95,13.624,14.17,24.117c-1.781,10.493-5.761,17.983-8.729,20.276c-2.967,2.293-15.665,6.327-14.244,0
                    c1.421-6.327-3.726-10.305-3.726-10.305l-1.537,14.571c0,0,6.818,10.21,14.894,8.418c8.075-1.794,25.688-13.066,26.281-29.491
                    c0.593-16.423-1.982-24.619-7.918-30.467c-5.935-5.848-18.004-15.431-22.159-13.057c-4.154,2.374-10.326,6.528-11.098,13.057
                    C163.557,69.89,165.875,88.288,165.875,88.288"/>
                  <path fill="#EFE4E1" d="M129.526,137.055c0,0,2.469,100.107-8.664,103.726s49.85,9.533,48.911-76.504s3.719-93.981,3.719-93.981l0,0
                    C173.492,70.296,176.99,113.792,129.526,137.055z"/>
                  <path fill="#FFFAF8" d="M2.604,90.176c7.057,25.837,55.633,50.308,90.13,50.417c45.725,0.146,70.224-14.2,76.599-61.231
                    c6.375-47.029-21.98-81.114-80.277-79.292C30.76,1.894-11.067,40.129,2.604,90.176z"/>
                  <path fill="#C1B5B0" d="M6.567,76.547c3.327,48.005,59.596,43.846,93.158,45.654c33.56,1.809,59.066-1.354,64.707-42.938
                    c5.636-41.585-23.156-70.312-75.061-69.337C37.814,10.894,3.345,30.047,6.567,76.547z"/>
                  <path fill="#4C3633" d="M6.756,83.925c7.596,30.394,54.562,47.044,85.223,48.64c44.271,2.302,71.769-29.657,72.452-53.301
                    c0.698-24.211-30.201-50.601-77.294-49.178C40.046,31.508-0.164,56.239,6.756,83.925z"/>
                  <path className="coffee" fill="#563B32" d="M24.105,83.925c6.826,30.394,49.04,47.044,76.598,48.64c39.789,2.302,64.507-29.657,65.119-53.301
                    c0.631-24.211-27.144-50.601-69.471-49.178C54.027,31.508,17.886,56.239,24.105,83.925z"/>
                  <path className="letter" fill="#4C4B48" d="M53.137,170.521l-10.273-21.625l2.019-0.682v-3.148l-8.988-0.749l-3.397,2.232l5.617,4.131l-9.221,25.307
                    l7.357,4.111l0.503-12.303h9.357l3.773,5.032v3.698l6.112-0.343l2.674-3.684L53.137,170.521z M38.032,162.795l3.264-3.89l2.451,3.89
                    H38.032z"/>
                  <polyline className="letter" fill="#4C4B48" points="115.883,155.795 115.883,184.436 114.398,189.232 141.57,188.024 137.883,185.08 137.883,155.947
                    139.422,153.795 130.321,153.795 129.653,174.795 125.573,174.795 124.289,151.051 "/>
                  <polygon className="letter" fill="#4C4B48" points="142.415,146.451 144.462,158.321 148.883,152.386 148.883,179.688 153.883,178.403 153.883,150.795
                    156.883,150.795 156.883,141.703 "/>
                  <polyline className="letter" fill="#4C4B48" points="63.474,205.076 58.133,224.942 53.277,226.488 63.974,230.172 67.374,211.755 72.814,219.609
                    75.883,212.133 75.883,229.542 88.756,230.098 84.861,225.98 84.048,204.949 88.776,200.795 75.719,200.795 70.985,207.421
                    65.722,200.795 59.101,200.795 63.348,204.654 "/>
                  <polygon className="letter" fill="#4C4B48" points="97.594,200.439 99.883,204.814 99.883,224.794 95.503,229.795 118.883,229.795 118.883,222.42
                    108.883,223.013 108.883,217.672 118.883,217.277 118.883,210.55 108.883,210.749 108.883,205.802 116.367,204.614 120.883,205.802
                    120.883,198.68 116.212,200.263 "/>
                  <path className="letter" fill="#4C4B48" d="M73.345,169.795l8.207-10.321l-1.193-8.679H56.924l4.959,5.602v28.039l-4.557,3.589l24.328-2.058
                    l-1.295-16.172H73.345z M74.829,158.795l-5.912,5.935l-0.32-5.935H74.829z M74.235,179.795h-5.353v-4.855l6.836,4.855H74.235z"/>
                  <path className="letter" fill="#4C4B48" d="M89.883,153.573v35.61l19-2.057v-31.18L89.883,153.573z M101.883,177.313l-5,2.374v-15.728l5,1.484V177.313z
                    "/>
                </g>
            </svg>

          </button>

          <button id="work" className="item" onClick={() => setActiveSection('work')} >

            <svg version="1.1"

              x="0px" y="0px" width="539px" height="418px" viewBox="0 0 539 418" overflow="visible" enableBackground="new 0 0 539 418"
              >

                  <g>
                  <defs>
                  </defs>
                  <path fill="#3A382F" d="M7.61,206.436c0,36.487,27.023,152.237,42.652,201.703c15.627,49.463,427.74-106.862,478.734-134.06
                    C579.99,246.88,409.855,17.035,409.855,17.035L0,133.112L7.61,206.436z"/>
                  <path fill="#28261E" d="M364.268,116.889h0.003c0,0,43.54-63.252,31.582-64.314c-11.956-1.062-44.034,55.082-44.041,55.093
                    c-32.608-23.812-75.93-33.819-112.829-32.641c-69.254,2.203-120.821,60.072-127.217,112.903
                    c-2.097,17.324-0.978,87.546,44.224,119.602c54.314,38.514,147.701,27.69,175.39,18.129c50.734-17.516,70.568-73.144,69.934-122.755
                    C400.843,166.147,386.144,139.889,364.268,116.889z"/>
                  <path fill="#F4ECDC" d="M71.405,191.312c7.01-57.889,63.514-121.2,139.399-123.613c75.886-2.423,176.572,39,177.875,140.719
                    c0.696,54.365-21.04,114.549-76.632,133.742c-30.34,10.477-132.669,22.359-192.183-19.842
                    C70.333,287.192,69.107,210.295,71.405,191.312z"/>
                  <path className="soup" fill="#CC4E36" d="M95.527,197.075c5.937-49.03,53.796-102.655,118.071-104.702c64.274-2.049,149.557,33.031,150.66,119.191
                    c0.588,46.05-17.82,97.021-64.906,113.279c-25.699,8.874-112.372,18.938-162.781-16.807
                    C94.619,278.285,93.581,213.153,95.527,197.075z"/>
                  <path className="spoon" fill="#B7ABA1" d="M284.914,137.004c0,0,92.383-142.811,103.931-136.821c11.547,5.992,3.849,11.225,0,24.831
                    c-3.85,13.606-90.459,113.821-94.308,119.185C290.688,149.564,297.423,138.051,284.914,137.004z"/>
                  <path className="letter" fill="#F9DB87" d="M150.131,179.574c1.919,3.306,13.142,26.797,16.428,16.335c3.287-10.462,3.287-16.335,3.287-16.335
                    s15.88,22.301,15.88,16.004c0-6.298,10.211-27.106,4.557-28.75c-5.652-1.643-8.391,12.745-8.391,12.745s-9.856-15.198-13.141-12.876
                    c-3.287,2.321-7.86,8.646-6.943,12.876c0.918,4.231-8.939-8.354-8.939-8.354S143.169,167.577,150.131,179.574z"/>
                  <path className="letter" fill="#F9DB87" d="M199.243,195.992c0.806,5.669,5.554,23.923,6.655,8.493c1.103-15.438,0.044-9.596,0.044-9.596h10.468l1.83,8
                    c0,0,5.416,15,5.416,0s0-22.391,0-22.391s-3.268-3.858-5.649,0c-2.382,3.855-2.086,9.915-2.086,9.915l-10.588,0.55
                    c0,0,0.581-9.917-2.725-9.364C199.303,182.151,198.449,190.408,199.243,195.992z"/>
                  <path className="letter" fill="#F9DB87" d="M262.057,199.986c-3.213-11.088-8.734-25.613-10.984-26.448c0,0-7.364-3.813-14.41,17.048
                    c-7.046,20.859,3.811,14.64,3.811,9.4c0-5.242,2.206-10.167,2.206-10.167l9.603,1.15c0,0,1.898,6.747,5.046,11.319
                    C260.476,206.859,263.664,205.529,262.057,199.986z M243.729,187.028c0,0,2.589-7.187,4.259-5.796
                    c1.669,1.39,2.037,5.499,2.037,5.499L243.729,187.028z"/>
                  <path className="letter" fill="#F9DB87" d="M276.252,176.889h-11.548c0,0-11.454-5.617,3.255-8.125c14.709-2.51,29.168,0.134,29.168,0.134
                    s3.062,8.993-6.982,7.766c-10.044-1.227-4.271,0.034-4.271,0.034l-0.643,18.679c0,0-0.895,13.32-6.221,2.358
                    C273.686,186.774,276.252,176.889,276.252,176.889z"/>
                  <path className="letter" fill="#F9DB87" d="M202.342,229.397c1.924-0.002-0.295,27.908-0.295,27.908s-6.702,10.63-7.077-1.551
                    c-0.396-12.882,1.217-20.775,2.99-25.04C199.151,227.846,201.569,229.397,202.342,229.397z"/>
                  <path className="letter" fill="#F9DB87" d="M244.759,246.773c-5.788-13.946-14.256-12.816-15.5-13.009c-1.245-0.191-2.587,0.141-2.74,2.472
                    c-0.153,2.366,0,26.422,2.74,25.329C231.999,260.469,250.546,260.717,244.759,246.773z M232.718,254.11
                    c-1.43,0.572-1.51-11.985-1.43-13.218c0.08-1.22,0.78-1.391,1.43-1.292c0.65,0.1,5.069-0.489,8.089,6.789
                    C243.829,253.669,234.147,253.54,232.718,254.11z"/>
                  <path className="letter" fill="#F9DB87" d="M274.971,235.498c-11.637-12.254-18.023,0-18.363,5.445c-0.837,13.446,1.932,14.708,7.191,17.621
                    C273.686,264.039,286.519,247.66,274.971,235.498z M265.939,252.987c-3.114-1.55-4.754-2.221-4.257-9.377
                    c0.199-2.898,3.98-9.42,10.871-2.898C279.389,247.185,271.792,255.902,265.939,252.987z"/>
                  <path fill="#DB8545" d="M220.458,128.53c-10.149,2.887-11.366,16.359-11.366,16.359h22.894L220.458,128.53z"/>
                  <path fill="#BF8C6A" d="M310.422,292.497c-4.737-9.428-18.198-8.101-18.198-8.101l-8.08,14.354L310.422,292.497z"/>
                </g>
            </svg>

          </button>

          <svg className="mint" data-tooltip title="I actually hate these kinds of mints" version="1.1"

            x="0px" y="0px" width="126px" height="70px" viewBox="-0.142 0.94 126 70"
            overflow="visible" enableBackground="new -0.142 0.94 126 70" >

              <g>
                <defs>
                </defs>
                <ellipse fill="#BCC7C6" cx="65.799" cy="37.287" rx="35.119" ry="31.75"/>
                <path fill="#BCC7C6" d="M96.317,23.506l15.826-16.047c0,0,0.641,9.86,5.13,10.223c4.489,0.361,4.489,5.824,4.489,5.824l4.492,4.158
                  l-3.848,7.696l1.279,3.851l-27.369-6.807"/>
                <path fill="#BCC7C6" d="M37.727,43.965L22.298,60.396c0,0-0.883-9.843-5.38-10.095c-4.497-0.251-4.629-5.716-4.629-5.716
                  l-4.59-4.047l3.657-7.788L9.98,28.935l27.531,6.137"/>
                <ellipse fill="#EFEFEF" cx="61.951" cy="33.438" rx="35.119" ry="31.752"/>
                <path fill="#FFFFFF" d="M92.469,17.734l15.824-16.047c0,0,0.644,9.859,5.133,10.221s4.489,5.826,4.489,5.826l4.492,4.156
                  l-3.851,7.697l1.282,3.85l-27.37-6.807"/>
                <path fill="#FFFFFF" d="M30.03,43.968L14.602,60.396c0,0-0.882-9.843-5.38-10.095C4.725,50.05,4.59,44.588,4.59,44.588L0,40.538
                  l3.66-7.787l-1.375-3.816l27.529,6.137"/>
                <ellipse fill="#FFFFFF" cx="66.643" cy="26.164" rx="28.265" ry="25.555"/>
                <path fill="#D63225" d="M69.809,63.266c0,0-1.654-25.102-3.167-29.828c0,0,9.172,27.396,6.169,29.573
                  C69.809,65.189,69.809,63.266,69.809,63.266z"/>
                <path fill="#D63225" d="M85.298,56.46c0,0-10.001-19.431-12.842-22.671c0,0,16.785,18.653,15.147,21.431
                  C85.963,57.994,85.298,56.46,85.298,56.46z"/>
                <path fill="#D63225" d="M94.041,45.022c-7.055-7.582-5.343-9.127-18.538-18.583c0,0,21.646,13.474,20.623,16.741
                  C95.101,46.448,94.041,45.022,94.041,45.022z"/>
                <path fill="#D63225" d="M53.561,64.545c0,0,5.986-26.376,8.332-30.748c0,0-1.985,29.447-5.335,31.043
                  C53.211,66.44,53.561,64.545,53.561,64.545z"/>
                <path fill="#D63225" d="M40.888,58.302c0,0,11.513-22.364,14.782-26.098c0,0-19.321,21.474-17.435,24.669
                  C40.122,60.069,40.888,58.302,40.888,58.302z"/>
                <path fill="#D63225" d="M32.334,48.986c7.647-8.215,5.787-9.89,20.087-20.138c0,0-23.455,14.6-22.346,18.14
                  C31.187,50.529,32.334,48.986,32.334,48.986z"/>
                <path fill="#D63225" d="M27.847,31.28c2.134-0.531,22.489-6.9,27.691-6.36c0,0-26.623,0.444-28.033,3.584
                  C26.098,31.646,27.847,31.28,27.847,31.28z"/>
                <path fill="#D63225" d="M32.538,16.798c10.064,1.355,10.33-0.894,25.374,4.315c0,0-23.045-9.697-25.08-7.028
                  C30.797,16.757,32.538,16.798,32.538,16.798z"/>
                <path fill="#D63225" d="M43.456,6.807c6.317,4.3,7.256,2.875,15.63,11.443c0,0-12.251-14.281-14.52-13.17
                  C42.298,6.195,43.456,6.807,43.456,6.807z"/>
                <path fill="#D63225" d="M77.035,2.655c0,0-10.251,13.761-11.56,16.884c0,0,7.367-18.279,9.89-18.079
                  C77.889,1.658,77.035,2.655,77.035,2.655z"/>
                <path fill="#D63225" d="M63.066,0.514c0,0-0.609,14.83-0.016,17.698c0,0-3.663-16.648-1.765-17.737
                  C63.186-0.616,63.066,0.514,63.066,0.514z"/>
                <path fill="#D63225" d="M87.265,8.156c0,0-13.537,10.109-16.42,11.236c0,0,12.877-13.189,15.116-12.591S87.265,8.156,87.265,8.156z"
                  />
                <path fill="#D63225" d="M94.643,25.573c-6.976-0.896-6.75-2.446-17.74-1.657c0,0,17.146-2.357,18.028-0.208
                  C95.811,25.858,94.643,25.573,94.643,25.573z"/>
                <path fill="#D63225" d="M91.354,14.278c-1.274,0.516-13.937,4.785-16.372,6.973c0,0,13.653-9.577,15.523-8.504
                  C92.374,13.82,91.354,14.278,91.354,14.278z"/>
              </g>
          </svg>

          </div>


        </section>
      }
      { (mode === 'plainText' || activeSection === 'about') &&
          <Section
            mode={ mode }
            setActiveSection = { setActiveSection }
            sectionId="about"
            sectionName="About Me"
            sectionContent={ homepageModals.find(modal => modal.id === '5').modalContent}
        />
      }
      { (mode === 'plainText' || activeSection === 'contact') &&
        <Section
          mode={ mode }
          setActiveSection = { setActiveSection }
          sectionId="contact"
          sectionName="Contact Me"
          sectionContent={ homepageModals.find(modal => modal.id === '12').modalContent}
        />
      }

      { (mode === 'plainText' || activeSection === 'work') &&
        <Section
          mode={ mode }
          setActiveSection = { setActiveSection }
          sectionId="work"
          sectionName="Work"
          sectionContent={ homepageModals.find(modal => modal.id === '9').modalContent}
        />
      }
    </div>
  )
}

export default Home;
